import './index.scss'

import Swiper from 'swiper/js/swiper.min'
import 'swiper/css/swiper.min.css'

import '@fancyapps/fancybox/dist/jquery.fancybox.min'
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'

import Hamster from 'hamsterjs'

import device from 'current-device'

const isDev = process.env.NODE_ENV === 'development'

// page swiper
// search
!(function () {
  let $indexSwiperEl = $('.swiper')
  let $rootEl = $('.root')
  let swiper = null
  let $asideEl = $('.aside')
  let $asideCenterContentElArr = $('.aside-center [class$="content"]')
  let $asideTitleLineElArr = $('.aside-title-line')
  let $asidePaginationElArr = $('.aside-pagination')
  let $asidePaginationItemElArr = $('.aside-pagination-item')
  let $asideBtnEl = $('.aside-btn')
  let $swiperHeaderEl = $('.swiper-header')
  let $swiperHeaderBodyEl = $('.swiper-header-body')
  let $swiperHeaderCHeaderEl = $('.swiper-header .cheader')
  let $swiperHeaderHamburgerEl = $('.swiper-header-hamburger')
  let $searchEl = $('.search')
  let $toggleSearchBtnEl = $('.aside .iconfont.icon-search')
  let $searchInputEl = $('.search input')
  let $footerEl = $('.footer')
  let $asideMoreEl = $('.aside-btn a, .aside-btn button').eq(0)
  let showFooter = false
  
  // 100vh
  device.mobile() && $rootEl.css('height', innerHeight)
  
  // init swiper
  swiper = new Swiper($indexSwiperEl, {
    direction: 'vertical',
    speed: 700,
    mousewheel: {
      releaseOnEdges: true
    },
    on: {
      init () {
        swiperChange.call(this)
      },
      transitionStart () {
        swiperChange.call(this)
      }
    }
  })
  
  // 切换点点击
  $asidePaginationItemElArr.on('click', function () {
    swiper.slideTo(
      $asidePaginationItemElArr.index($(this))
    )
  })
  
  // 打开和关闭 search
  $toggleSearchBtnEl.on('click', function () {
    let isOpen = $searchEl.hasClass('active')
    isOpen && closeSeach()
    isOpen || openSearch()
  })
  
  // 给最后一屏绑定鼠标滚动监听，显示 footer
  // Hamster(swiper.slides[ swiper.slides.length - 1 ])
  //   .wheel(function (event, delta, deltaX, deltaY) {
  //     let { progress, animating } = swiper
  //     // 若鼠标往下滚，显示 footer
  //     if (deltaY < 0 && progress === 1 && animating === false) {
  //       showFooter = true
  //       $footerEl.addClass('active')
  //     }
  //     // 若往上滚，显示着 footer
  //     else if (deltaY > 0 && showFooter === true) {
  //       event.stopPropagation()
  //       $footerEl.removeClass('active')
  //       // 防颤抖，防止上滚多次造成的问题
  //       setTimeout(() => {
  //         showFooter = false
  //       }, 350)
  //     }
  //   })
  
  // 初始化 aside 切换内容元素的 间隔时间
  $asideCenterContentElArr.each((i, el) => {
    let $el = $(el)
    $el.css({
      transitionDelay: 0.04 * i + 's'
    })
  })
  
  // test aside 按钮
  if (isDev) {
    $asideMoreEl.attr('data-href-2', '1')
    $asideMoreEl.attr('data-href-3', '2')
    $asideMoreEl.attr('data-href-4', '3')
  }
  
  // 需要 swiper this
  function swiperChange () {
    let i = this.realIndex
    
    // 改变切换点
    $asidePaginationItemElArr.removeClass('active').eq(i).addClass('active')
    
    // 改变 aside center 中的内容
    $asideCenterContentElArr.css({
      transform: `translateY(-${ 100 / 5 * i }%)`
    })
    
    // 隐藏 footer
    $footerEl.removeClass('active')
    
    // 消除默认所有影响
    $swiperHeaderEl.removeClass('active')
    $swiperHeaderHamburgerEl.removeClass('white')
    $asideEl.attr('style', '')
    $asideBtnEl.attr('style', '')
    $swiperHeaderEl.attr('style', '')
    $asideTitleLineElArr.attr('style', '')
    $asidePaginationElArr.attr('style', '')
    $swiperHeaderHamburgerEl.attr('style', '')
    
    // 若不是第一屏，则关闭搜索
    i !== 0 && closeSeach()
    
    // aside 详细了解按钮不同链接
    $asideMoreEl.attr(
      'href',
      $asideMoreEl.data(`href-${ i + 1 }`)
    )
    
    // 根据不同屏幕进行特殊操作
    switch (i) {
      case 0: {
        $asideEl.css('background', 'transparent')
        $asideTitleLineElArr.css('opacity', 0)
        $swiperHeaderEl.addClass('active')
        $swiperHeaderCHeaderEl.css('background', 'transparent')
        $swiperHeaderHamburgerEl.css({
          visibility: 'hidden',
          opacity: 0
        })
        $asidePaginationElArr.css({
          opacity: 0,
          visibility: 'hidden'
        })
        $asideBtnEl.css({
          opacity: 0,
          visibility: 'hidden'
        })
        break
      }
      case 1: {
        $asideEl.css('background', '#a98457')
        $swiperHeaderCHeaderEl.css('background', '#a98457')
        break
      }
      case 2: {
        $asideEl.css('background', '#a98457')
        $swiperHeaderCHeaderEl.css('background', '#CBA03A')
        $swiperHeaderHamburgerEl.addClass('white')
        break
      }
      case 3: {
        $asideEl.css('background', '#a98457')
        $swiperHeaderCHeaderEl.css('background', '#1A6F94')
        $swiperHeaderHamburgerEl.addClass('white')
        break
      }
      case 4: {
        $asideEl.css({
          opacity: 0,
          visibility: 'hidden'
        })
        $asideTitleLineElArr.css('opacity', 0)
        $swiperHeaderHamburgerEl.css({
          visibility: 'hidden',
          opacity: 0
        })
        $swiperHeaderEl.css({
          visibility: 'hidden',
          opacity: 0
        })
        break
      }
    }
  }
  
  function openSearch () {
    $searchEl.addClass('active')
    $toggleSearchBtnEl.removeClass('icon-search').addClass('icon-close')
    setTimeout(() => {
      $searchInputEl.focus()
    }, 20)
  }
  
  function closeSeach () {
    $searchEl.removeClass('active')
    $toggleSearchBtnEl.addClass('icon-search').removeClass('icon-close')
  }
  
})()

// banner swiper
!(function () {
  const AUTO_PLAY_DELAY = 6000
  
  let $bannerEl = $('.banner')
  let $paginationEl = $('.banner-pagination')
  let $paginationIndexEl = $('.banner-pagination-index')
  let $paginationItemElArr = $('.banner-pagination-body > *')
  let $paginationProgressEl = $('.banner-progress')
  
  let swiper = new Swiper($bannerEl, {
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    autoplay: {
      delay: AUTO_PLAY_DELAY,
      disableOnInteraction: false
    },
    on: {
      transitionStart () {
        let i = this.realIndex
        
        $paginationIndexEl.html(`0${ i + 1 }`)
        $paginationItemElArr.removeClass('active').eq(i).addClass('active')
        $paginationProgressEl.css({
          transitionDuration: '0ms',
          width: '0%'
        })
      },
      transitionEnd () {
        $paginationProgressEl.css('display')
        $paginationProgressEl.attr('style', '')
      }
    }
  })
  
  $paginationItemElArr.on('click', function () {
    console.log($paginationItemElArr.index($(this)))
    swiper.slideTo(
      $paginationItemElArr.index($(this))
    )
  })
})()

// link
!(function () {
  let $picElArr = $('.link-pic-item')
  let $linkElArr = $('.link-item')
  
  $linkElArr.on('mouseenter', function () {
    let i = $linkElArr.index($(this))
    
    $picElArr.removeClass('active').eq(i).addClass('active')
  })
})()

// other
!(function () {
  let $newsSwiperEl = $('.other-news-swiper')
  let swiper = new Swiper($newsSwiperEl, {
    watchOverflow: true,
    pagination: {
      el: '.other-news-index',
      type: 'fraction',
      modifierClass: 'asdjwdkzx',
      renderFraction: function (currentClass, totalClass) {
        return `<b class="${ currentClass }"></b>/<span class="${ totalClass }"></span>`
      }
    },
    navigation: {
      nextEl: '.other-news-control.next',
      prevEl: '.other-news-control.prev'
    }
  })
})()

// intro
!(function () {
  let $wenhuaSwiperEl = $('.intro-other-swiper')
  let wenhuaSwiper = new Swiper($wenhuaSwiperEl, {
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    navigation: {
      prevEl: '.intro-other-control.prev',
      nextEl: '.intro-other-control.next'
    }
  })
  let mediaSwiper = new Swiper('.intro-media-swiper', {
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    effect: 'fade',
    watchOverflow: true,
    fadeEffect: {
      crossFade: true
    },
    pagination: {
      el: '.intro-media-pagination',
      // type: 'fraction',
      // modifierClass: 'asdjwdkasdwdx',
      // renderFraction: function (currentClass, totalClass) {
      //   return `<b class="${ currentClass }"></b>/<span class="${ totalClass }"></span>`
      // }
    }
  })
})()
